import { formatAbiItem, formatAbiParams } from '../utils/abi/formatAbiItem.js';
import { size } from '../utils/data/size.js';
import { BaseError } from './base.js';
export class AbiConstructorNotFoundError extends BaseError {
  constructor({
    docsPath
  }) {
    super(['A constructor was not found on the ABI.', 'Make sure you are using the correct ABI and that the constructor exists on it.'].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiConstructorNotFoundError'
    });
  }
}
export class AbiConstructorParamsNotFoundError extends BaseError {
  constructor({
    docsPath
  }) {
    super(['Constructor arguments were provided (`args`), but a constructor parameters (`inputs`) were not found on the ABI.', 'Make sure you are using the correct ABI, and that the `inputs` attribute on the constructor exists.'].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiConstructorParamsNotFoundError'
    });
  }
}
export class AbiDecodingDataSizeInvalidError extends BaseError {
  constructor({
    data,
    size
  }) {
    super([`Data size of ${size} bytes is invalid.`, 'Size must be in increments of 32 bytes (size % 32 === 0).'].join('\n'), {
      metaMessages: [`Data: ${data} (${size} bytes)`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiDecodingDataSizeInvalidError'
    });
  }
}
export class AbiDecodingDataSizeTooSmallError extends BaseError {
  constructor({
    data,
    params,
    size
  }) {
    super([`Data size of ${size} bytes is too small for given parameters.`].join('\n'), {
      metaMessages: [`Params: (${formatAbiParams(params, {
        includeName: true
      })})`, `Data:   ${data} (${size} bytes)`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiDecodingDataSizeTooSmallError'
    });
    Object.defineProperty(this, "data", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "params", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "size", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    this.data = data;
    this.params = params;
    this.size = size;
  }
}
export class AbiDecodingOffsetOutOfBoundsError extends BaseError {
  constructor({
    offset,
    position
  }) {
    super(`Offset at "${offset}" is out-of-bounds (current position: "${position}").`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiDecodingOffsetOutOfBoundsError'
    });
  }
}
export class AbiDecodingZeroDataError extends BaseError {
  constructor() {
    super('Cannot decode zero data ("0x") with ABI parameters.');
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiDecodingZeroDataError'
    });
  }
}
export class AbiEncodingArrayLengthMismatchError extends BaseError {
  constructor({
    expectedLength,
    givenLength,
    type
  }) {
    super([`ABI encoding array length mismatch for type ${type}.`, `Expected length: ${expectedLength}`, `Given length: ${givenLength}`].join('\n'));
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiEncodingArrayLengthMismatchError'
    });
  }
}
export class AbiEncodingBytesSizeMismatchError extends BaseError {
  constructor({
    expectedSize,
    value
  }) {
    super(`Size of bytes "${value}" (bytes${size(value)}) does not match expected size (bytes${expectedSize}).`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiEncodingBytesSizeMismatchError'
    });
  }
}
export class AbiEncodingLengthMismatchError extends BaseError {
  constructor({
    expectedLength,
    givenLength
  }) {
    super(['ABI encoding params/values length mismatch.', `Expected length (params): ${expectedLength}`, `Given length (values): ${givenLength}`].join('\n'));
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiEncodingLengthMismatchError'
    });
  }
}
export class AbiErrorInputsNotFoundError extends BaseError {
  constructor(errorName, {
    docsPath
  }) {
    super([`Arguments (\`args\`) were provided to "${errorName}", but "${errorName}" on the ABI does not contain any parameters (\`inputs\`).`, 'Cannot encode error result without knowing what the parameter types are.', 'Make sure you are using the correct ABI and that the inputs exist on it.'].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiErrorInputsNotFoundError'
    });
  }
}
export class AbiErrorNotFoundError extends BaseError {
  constructor(errorName, {
    docsPath
  } = {}) {
    super([`Error ${errorName ? `"${errorName}" ` : ''}not found on ABI.`, 'Make sure you are using the correct ABI and that the error exists on it.'].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiErrorNotFoundError'
    });
  }
}
export class AbiErrorSignatureNotFoundError extends BaseError {
  constructor(signature, {
    docsPath
  }) {
    super([`Encoded error signature "${signature}" not found on ABI.`, 'Make sure you are using the correct ABI and that the error exists on it.', `You can look up the decoded signature here: https://openchain.xyz/signatures?query=${signature}.`].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiErrorSignatureNotFoundError'
    });
    Object.defineProperty(this, "signature", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    this.signature = signature;
  }
}
export class AbiEventSignatureEmptyTopicsError extends BaseError {
  constructor({
    docsPath
  }) {
    super('Cannot extract event signature from empty topics.', {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiEventSignatureEmptyTopicsError'
    });
  }
}
export class AbiEventSignatureNotFoundError extends BaseError {
  constructor(signature, {
    docsPath
  }) {
    super([`Encoded event signature "${signature}" not found on ABI.`, 'Make sure you are using the correct ABI and that the event exists on it.', `You can look up the signature here: https://openchain.xyz/signatures?query=${signature}.`].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiEventSignatureNotFoundError'
    });
  }
}
export class AbiEventNotFoundError extends BaseError {
  constructor(eventName, {
    docsPath
  } = {}) {
    super([`Event ${eventName ? `"${eventName}" ` : ''}not found on ABI.`, 'Make sure you are using the correct ABI and that the event exists on it.'].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiEventNotFoundError'
    });
  }
}
export class AbiFunctionNotFoundError extends BaseError {
  constructor(functionName, {
    docsPath
  } = {}) {
    super([`Function ${functionName ? `"${functionName}" ` : ''}not found on ABI.`, 'Make sure you are using the correct ABI and that the function exists on it.'].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiFunctionNotFoundError'
    });
  }
}
export class AbiFunctionOutputsNotFoundError extends BaseError {
  constructor(functionName, {
    docsPath
  }) {
    super([`Function "${functionName}" does not contain any \`outputs\` on ABI.`, 'Cannot decode function result without knowing what the parameter types are.', 'Make sure you are using the correct ABI and that the function exists on it.'].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiFunctionOutputsNotFoundError'
    });
  }
}
export class AbiFunctionSignatureNotFoundError extends BaseError {
  constructor(signature, {
    docsPath
  }) {
    super([`Encoded function signature "${signature}" not found on ABI.`, 'Make sure you are using the correct ABI and that the function exists on it.', `You can look up the signature here: https://openchain.xyz/signatures?query=${signature}.`].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiFunctionSignatureNotFoundError'
    });
  }
}
export class AbiItemAmbiguityError extends BaseError {
  constructor(x, y) {
    super('Found ambiguous types in overloaded ABI items.', {
      metaMessages: [`\`${x.type}\` in \`${formatAbiItem(x.abiItem)}\`, and`, `\`${y.type}\` in \`${formatAbiItem(y.abiItem)}\``, '', 'These types encode differently and cannot be distinguished at runtime.', 'Remove one of the ambiguous items in the ABI.']
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'AbiItemAmbiguityError'
    });
  }
}
export class BytesSizeMismatchError extends BaseError {
  constructor({
    expectedSize,
    givenSize
  }) {
    super(`Expected bytes${expectedSize}, got bytes${givenSize}.`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'BytesSizeMismatchError'
    });
  }
}
export class DecodeLogDataMismatch extends BaseError {
  constructor({
    abiItem,
    data,
    params,
    size
  }) {
    super([`Data size of ${size} bytes is too small for non-indexed event parameters.`].join('\n'), {
      metaMessages: [`Params: (${formatAbiParams(params, {
        includeName: true
      })})`, `Data:   ${data} (${size} bytes)`]
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'DecodeLogDataMismatch'
    });
    Object.defineProperty(this, "abiItem", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "data", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "params", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    Object.defineProperty(this, "size", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    this.abiItem = abiItem;
    this.data = data;
    this.params = params;
    this.size = size;
  }
}
export class DecodeLogTopicsMismatch extends BaseError {
  constructor({
    abiItem,
    param
  }) {
    super([`Expected a topic for indexed event parameter${param.name ? ` "${param.name}"` : ''} on event "${formatAbiItem(abiItem, {
      includeName: true
    })}".`].join('\n'));
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'DecodeLogTopicsMismatch'
    });
    Object.defineProperty(this, "abiItem", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: void 0
    });
    this.abiItem = abiItem;
  }
}
export class InvalidAbiEncodingTypeError extends BaseError {
  constructor(type, {
    docsPath
  }) {
    super([`Type "${type}" is not a valid encoding type.`, 'Please provide a valid ABI type.'].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidAbiEncodingType'
    });
  }
}
export class InvalidAbiDecodingTypeError extends BaseError {
  constructor(type, {
    docsPath
  }) {
    super([`Type "${type}" is not a valid decoding type.`, 'Please provide a valid ABI type.'].join('\n'), {
      docsPath
    });
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidAbiDecodingType'
    });
  }
}
export class InvalidArrayError extends BaseError {
  constructor(value) {
    super([`Value "${value}" is not a valid array.`].join('\n'));
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidArrayError'
    });
  }
}
export class InvalidDefinitionTypeError extends BaseError {
  constructor(type) {
    super([`"${type}" is not a valid definition type.`, 'Valid types: "function", "event", "error"'].join('\n'));
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'InvalidDefinitionTypeError'
    });
  }
}
export class UnsupportedPackedAbiType extends BaseError {
  constructor(type) {
    super(`Type "${type}" is not supported for packed encoding.`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'UnsupportedPackedAbiType'
    });
  }
}
