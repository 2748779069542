import { BaseError } from './base.js';
export class NegativeOffsetError extends BaseError {
  constructor({
    offset
  }) {
    super(`Offset \`${offset}\` cannot be negative.`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'NegativeOffsetError'
    });
  }
}
export class PositionOutOfBoundsError extends BaseError {
  constructor({
    length,
    position
  }) {
    super(`Position \`${position}\` is out of bounds (\`0 < position < ${length}\`).`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'PositionOutOfBoundsError'
    });
  }
}
export class RecursiveReadLimitExceededError extends BaseError {
  constructor({
    count,
    limit
  }) {
    super(`Recursive read limit of \`${limit}\` exceeded (recursive read count: \`${count}\`).`);
    Object.defineProperty(this, "name", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 'RecursiveReadLimitExceededError'
    });
  }
}
