import { InvalidAddressError } from '../../errors/address.js';
import { stringToBytes } from '../encoding/toBytes.js';
import { keccak256 } from '../hash/keccak256.js';
import { isAddress } from './isAddress.js';
export function checksumAddress(address_, chainId) {
  const hexAddress = chainId ? `${chainId}${address_.toLowerCase()}` : address_.substring(2).toLowerCase();
  const hash = keccak256(stringToBytes(hexAddress), 'bytes');
  const address = (chainId ? hexAddress.substring(`${chainId}0x`.length) : hexAddress).split('');
  for (let i = 0; i < 40; i += 2) {
    if (hash[i >> 1] >> 4 >= 8 && address[i]) {
      address[i] = address[i].toUpperCase();
    }
    if ((hash[i >> 1] & 0x0f) >= 8 && address[i + 1]) {
      address[i + 1] = address[i + 1].toUpperCase();
    }
  }
  return `0x${address.join('')}`;
}
export function getAddress(address, chainId) {
  if (!isAddress(address, {
    strict: false
  })) throw new InvalidAddressError({
    address
  });
  return checksumAddress(address, chainId);
}
